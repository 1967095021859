import React from 'react';
import {
  observable,
  action,
  flow,
  computed,
  makeObservable,
  reaction,
  IReactionDisposer,
} from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { DateRange } from 'components/DateRangeExternalPicker';

// api import for tips report email and download for QA testing:
import Api, { ApiResponse, PagedApiResponse, RequestMetaData, getErrorMsg } from 'api';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'types/stores';

import styles from './styles';

import { AxiosResponse } from 'axios';

import { Pound, CurrencyUsd } from 'mdi-material-ui';
import { TrendingUpOutlined } from '@mui/icons-material';
import Title from 'components/Title/Title';
import { RouteComponentProps } from 'react-router-dom';
import InvitationsListPanel from 'components/InvitationsListPanel';
import { Invitation } from 'models';
import { fetchWrapper } from 'services';

type TenantHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  RouteComponentProps;

/**
 * The owner dashboard, displayed when they log in.
 */
@inject('userStore', 'modalStore', 'toastStore')
@observer
class TenantHome extends React.Component<TenantHomeProps> {
  constructor(props: TenantHomeProps) {
    super(props);
    // makeObservable(this);
  }

  @action.bound public getInvitations = ({ filters, ...rmd }: RequestMetaData) => {
    return Api.core.getInvitations({
      filters: {
        email: this.props.userStore?.user?.email,
        ...filters,
      },
      ...rmd,
    });
  };

  render() {
    return (
      <>
        <Title mb={3}>Dashboard</Title>
        <Box display="flex" flexDirection="row">
          {/* {TenantHome} */}
        </Box>
        <Box pt={3}>
          <InvitationsListPanel fetch={this.getInvitations} />
        </Box>
      </>
    );
  }
}

export default withStyles(styles)(TenantHome);
