import React, { ReactNode } from 'react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Container, Paper, CircularProgress, Typography } from '@material-ui/core';
import logo from 'images/Logo_SHOPtree.svg';

import styles from './styles';

interface AnonLayoutProps extends WithStyles<typeof styles> {
  children: ReactNode;
  inProgress?: boolean;
  applyFooter?: boolean;
  icon?: any;
  hideIcon?: boolean;
}
/**
 * The layout wrapper to use for screens that are anon-only, so
 * login, signup, etc.
 */
function AnonLayout({
  classes,
  children,
  inProgress = false,
  applyFooter = false,
  icon,
  hideIcon,
}: AnonLayoutProps) {
  return (
    <Box className={classes.root}>
      <Icon icon={icon} hideIcon={hideIcon} applyFooter={applyFooter} classes={classes} />
      <Container className={classes.container}>
        <Paper>
          <Box className={classes.insideBox}>
            {inProgress && (
              <Box className={classes.loader}>
                <CircularProgress />
              </Box>
            )}
            {children}
          </Box>
        </Paper>
      </Container>
      {applyFooter && (
        <Box className={classes.footer}>
          <Box pt={3}>
            <Typography align="center" variant="subtitle1">
              If you have any questions or concerns, don’t hesitate to contact your SHOPtree Success
              Agent at 1.855.831.2323 or support@shoptree.com.
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography align="center" variant="subtitle1">
              Made by Direct Tips Operations LLC | 2755 E Oakland Park Blvd. Ste 300, Ft.
              Lauderdale, FL 33306
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
}

interface IIcon {
  icon?: React.ReactNode;
  classes: any;
  applyFooter: boolean;
  hideIcon?: boolean;
}
function Icon(props: IIcon): any {
  const { icon, classes, applyFooter, hideIcon } = props;
  if (hideIcon) return null;
  if (icon) return icon;
  return (
    <img
      src={logo}
      alt="MySHOPtree"
      className={classes.pig}
      style={{ marginTop: applyFooter ? 'auto' : 'none' }}
    />
  );
}

export default withStyles(styles)(AnonLayout);
