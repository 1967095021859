import { WithStyles, withStyles } from '@material-ui/core/styles';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { WithSettingStore, WithToastStore, inject } from 'types/stores';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import DashboardLayout from 'containers/DashboardLayout';
import styles from './styles';
import {
  Box,
  Dialog,
  Link,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import FilterBar from 'components/FilterBar';
import { Filter } from 'components/FilterBar/FilterBar';
import Title from 'components/Title';
import DataGridInfiniteScroll, { GridColumns } from 'components/DataGridInfiniteScroll';
import PlusFabButton from 'components/PlusFabButton/PlusFabButton';
import { fetchWrapper, setTitle } from 'services';
import Api, { RequestMetaData } from 'api';
import { Tenant } from 'models';
import { paths } from 'routes';
import ChipStatusTag from 'components/ChipStatusTag';
import Overlay from 'components/Overlay';
import clsx from 'clsx';
import Button from 'components/Button/Dialog/Button';
import CompanyForm from 'components/JSForm/CompanyForm';
import { Formik } from 'formik';
import { object, string, number } from 'yup';

const PAGE_TITLE = 'Workspaces';

export interface FilterItem {
  label: string;
  value: any;
}

type TenantsProps = WithStyles<typeof styles> &
  RouteComponentProps &
  WithToastStore &
  WithSettingStore;

/**
 * Container displaying active kiosk alert message if present and list of past alerts.
 */
@inject('userStore', 'toastStore', 'settingStore')
@observer
class Tenants extends React.Component<TenantsProps> {
  public constructor(props: TenantsProps) {
    super(props);
    makeObservable(this);
  }

  @observable private activeFilters: Record<string, unknown> = {};
  @observable private open = false;
  @observable private displayOverlay = false;

  public getTenants = fetchWrapper(
    async (rmd: RequestMetaData) => {
      const order: 'ASC' | 'DESC' = 'DESC';

      return await Api.core.getTenants({
        ...rmd,
        filters: {
          ...this.activeFilters,
        },
        sort: { sortBy: 'createdAt', sortOrder: order },
      });
    },

    (tenant: Tenant) => ({
      ...tenant,
      code: (tenant.code as string).toUpperCase(),
    }),
  );

  public async postAddTenant(data: Record<string, unknown>) {
    try {
      await Api.core.addTenant(data);
      this.props.toastStore!.push({
        type: 'success',
        message: `Tenant added successfully`,
      });
    } catch (error) {
      this.props.toastStore!.push({
        type: 'error',
        message: `${(error as Error).message}`,
      });
    }
  }

  onClose() {
    this.open = false;
  }

  onOpen() {
    this.open = true;
  }

  filters: Filter[] = [
    { display: 'Name', id: 'name', label: 'Contains', type: 'text' },
    { display: 'Code', id: 'code', label: 'Matches', type: 'text' },
    {
      display: 'Status',
      id: 'isActive',
      label: 'One of',
      type: 'select',
      items: [
        { label: 'ACTIVE', value: 'true' },
        { label: 'INACTIVE', value: 'false' },
      ],
    },
  ];

  renderCellName = (params: any) => (
    <Link component={RouterLink} to={paths.tenantDetails(params.id).info()}>
      {params.value}
    </Link>
  );

  renderCellSubscription = ({ value }: any) => {
    let text = 'unlinked'
    if(value){
      text = value.status
    }
    return <Typography>{text}</Typography>;
  }

  getFullName({ value }: any) {
    return `${value?.firstName || ''} ${value?.lastName || ''}`;
  }

  renderCellStatus({ value }: any) {
    return <ChipStatusTag status={value} />;
  }

  gridColumns: GridColumns = [
    {
      headerName: 'Name',
      field: 'name',
      minWidth: 200,
      renderCell: this.renderCellName,
      flex: 1,
    },
    { headerName: 'Code', field: 'code', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Owner',
      field: 'primaryOwner',
      minWidth: 150,
      flex: 1,
      valueGetter: this.getFullName,
    },
    { headerName: 'Stores', field: 'storeCount', minWidth: 110, flex: 1, sortable: false },
    { headerName: 'Users', field: 'userCount', minWidth: 100, flex: 1, sortable: false },
    {
      headerName: 'Subscription',
      field: 'subscription',
      renderCell: this.renderCellSubscription,
      minWidth: 100,
      flex: 1,
      sortable: false,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 150,
      sortable: false,
      renderCell: this.renderCellStatus,
    },
  ];

  renderDialog() {
    const { classes } = this.props;
    return (
      <Dialog
        open={this.open}
        onClose={() => this.onClose()}
        className={clsx(classes.dialog)}
        fullWidth>
        <Overlay display={this.displayOverlay}>
          <CircularProgress />
        </Overlay>
        <DialogTitle disableTypography>
          <Title mb={0}>Add Workspace</Title>
        </DialogTitle>
        <Box>
          <Formik
            initialValues={{
              name: '',
              address: '',
              address2: '',
              city: '',
              state: '',
              country: '',
              zip: '',
              lat: '',
              long: '',
            }}
            validationSchema={object({
              name: string().required(),
              address: string().required(),
              address2: string().optional(),
              city: string().required(),
              state: string().required(),
              zip: number().required(),
              lat: string().optional(),
              long: string().optional(),
            })}
            onSubmit={async (values, actions) => {
              const { name, ...address } = values;
              await this.postAddTenant({ name, address });
              setTimeout(() => {
                actions.setSubmitting(false);
                this.activeFilters = { ...this.activeFilters };
                this.onClose();
              }, 1000);
            }}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <DialogContent>
                  <CompanyForm formik={props} />
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" onClick={() => this.onClose()}>
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={!props.isValid}>
                    OK
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </Box>
      </Dialog>
    );
  }

  componentDidMount() {
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    return (
      <DashboardLayout>
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <Title mb={3}>{PAGE_TITLE}</Title>
        </Box>

        <FilterBar
          filters={this.filters}
          onChange={(filters: Record<string, unknown>) => {
            this.activeFilters = filters;
          }}
        />

        <DataGridInfiniteScroll
          columns={this.gridColumns}
          fetch={this.getTenants}
          refetchKey={this.activeFilters}
          disableColumnMenu
          pathname={this.props.location.pathname}
        />
        <PlusFabButton onClick={() => this.onOpen()} />
        {this.renderDialog()}
      </DashboardLayout>
    );
  }
}

export default withStyles(styles)(Tenants);
