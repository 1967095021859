import React from 'react';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  IconButton,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import validatorjs from 'validatorjs';
import { Close } from 'mdi-material-ui';

import { inject, WithUserStore, WithToastStore } from 'types/stores';
import { Address } from 'types';

import AddressField from 'components/AddressField';
import Overlay from 'components/Overlay';

import styles from './styles';
import Title from 'components/Title/Dialog/Title';
import OutlinedInput from 'components/Input/OutlinedInput';
import Button from 'components/Button/Button';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const dvr = require('mobx-react-form/lib/validators/DVR');
const MobxReactForm = require('mobx-react-form').default;



interface Business {
  name: string;
  address: Address|null;

}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MobxForm = any;
interface FormHooks {
  onSuccess: (form: MobxForm) => void;
  onClear?: (form: MobxForm) => void;
  onError?: (form: MobxForm) => void;
}

const plugins = {
  dvr: dvr({
    package: validatorjs,
  }),
};

/** Here we define what kind of props this component takes */
interface AddBusinessProps
  extends WithStyles<typeof styles>, // Adds the classes prop
    WithToastStore,
    WithUserStore {
  onClose: () => void;
  onSubmit: (v: Partial<Business>) => void;
  title: string;
}

const fields = [
  {
    name: 'name',
    label: 'Name',
    rules: 'required|min:2',
  },
  {
    name: 'address',
    label: 'Address',
    rules: 'required',
    value: null,
  },
];

/**
 * Container that displays the screen for adding a Store
 */
@inject('userStore', 'toastStore')
@observer
class AddBusiness extends React.Component<AddBusinessProps> {
  constructor(props: AddBusinessProps) {
    super(props);
    makeObservable(this);
  }

  hooks: FormHooks = {
    onSuccess: () => this.submit(),
  };

  form: MobxForm = new MobxReactForm({ fields }, { plugins, hooks: this.hooks });

  /** The address that's used for this Store */
  @computed public get address(): Address | undefined {
    const addr = this.form.$('address').value;
    if (!addr) {
      return undefined;
    }
    return {
      address: addr.address,
      address2: addr.address,
      city: addr.city,
      state: addr.state,
      country: addr.country,
      zip: addr.zip,
      lat: addr.lat,
      long: addr.long,
    };
  }

  /** The Store that's being added */
  @computed public get business(): Partial<Business> {
    return {
      name: this.form.$('name').value,
      address: this.address,
    };
  }

  /** Whether it's in progress */
  @observable public inProgress = false;

  /** Updates the address */
  @action.bound public updateAddress(a: Address | null) {
    this.form.$('address').set(a);
  }

  @action.bound public submit = flow(function* (this: AddBusiness) {
    try {
      this.inProgress = true;
      yield this.props.onSubmit(this.business)
    } catch (e) {
      this.props.toastStore!.error((e as Error).message);
    } finally {
      this.inProgress = false;
    }
  });

  render() {
    const { classes, onClose } = this.props;
    const addressField = this.form.$('address');
    const nameField = this.form.$('name');
    return (
      <Box className={classes.root}>
        <form onSubmit={this.form.onSubmit}>
          <DialogTitle>
            <Title mb={0}>
              <Typography style={{ fontSize: 28, fontWeight: 400 }}>Create {this.props.title}</Typography>
            </Title>
          </DialogTitle>
          <Overlay display={this.inProgress}>
            <CircularProgress />
          </Overlay>
          {onClose && (
            <IconButton onClick={onClose} className={classes.closeBtn}>
              <Close />
            </IconButton>
          )}
          <DialogContent>
            <Box mb={3}>
              <OutlinedInput
                {...nameField.bind()}
                error={Boolean(nameField.error)}
                helperText={nameField.error}
                fullWidth
                autoFocus
              />
            </Box>
            <Box mb={3}>
              <AddressField
                onChange={this.updateAddress}
                error={Boolean(addressField.error)}
                label="Address"
                helperText={addressField.error}
                onBlur={addressField.onBlur}
                onFocus={addressField.onFocus}
              />
            </Box>
            <Box pb={2}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Save
              </Button>
            </Box>
          </DialogContent>
        </form>
      </Box>
    );
  }
}

export default withStyles(styles)(AddBusiness);
