import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link } from '@material-ui/core';
import { paths } from 'routes';
import useStyles from './styles';
import logo from 'images/SHOPtree.svg';

interface IShopLogoProps {
  link?: boolean;
}
const ShopLogo = ({ link }: IShopLogoProps) => {
  const { link: linkStyles } = useStyles();
  const redirect = paths.root();
  if (link)
    return (
      <Link component={RouterLink} to={redirect} className={linkStyles}>
        <img width={'150px'} alt="logo" src={logo} />{' '}
      </Link>
    );
  return (
    <Box className={linkStyles}>
      {' '}
      <img alt="logo" src={logo} />{' '}
    </Box>
  );
};

export default ShopLogo;
