/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { ButtonBase, Box } from '@material-ui/core';
import { observer } from 'mobx-react';
import ButtonGroup from '@mui/material/ButtonGroup';
import clsx from 'clsx';

import useStyles from './styles';

export enum TabVariant {
  CONTAINED = 'contained',
  UNDERLINE = 'underline',
}

/**
 * TabBar root component that lets us build and use tab bar. TabBar also has
 * a Tab component that we use as one or more child of TabBar.
 */
const TabBar = ({
  children,
  mb,
  center,
  className,
}: {
  children: React.ReactNode;
  mb?: number;
  center?: boolean;
  className?: string;
}) => {
  const styles = useStyles({});
  const alignItems = center ? 'center' : 'space-around';
  return (
    <Box
      className={clsx(styles.root, {
        [className!]: Boolean(className),
      })}
      mb={mb}>
      <ButtonGroup
        style={{ justifyContent: alignItems }}
        fullWidth
        aria-label="full width outlined button group">
        {children}
      </ButtonGroup>
    </Box>
  );
};

interface TabProps {
  children: React.ReactNode;
  selected: boolean;
  onClick?: () => void;
}

/**
 * Tab is a sub-component of TabBar used for a single tab button. It has no logic
 * but is does accept 'selected' boolean state so we can apply active styles.
 */
TabBar.Tab = observer(
  ({ children, selected, onClick, variant = TabVariant.CONTAINED, mr, ...rest }: any) => {
    const { tabBase, contained, underline, spaceRight } = useStyles({ mr });
    const variantStyle = variant === TabVariant.CONTAINED ? contained : underline;
    return (
      <ButtonBase
        {...rest}
        className={clsx(tabBase, spaceRight, variantStyle, {
          selected: selected,
        })}
        onClick={onClick}>
        {children}
      </ButtonBase>
    );
  },
);

export default TabBar;
