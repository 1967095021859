import React, { useEffect, useState } from 'react';
import api from 'api';
import {
  Box,
  Chip,
  CircularProgress,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useStyles } from './styles';
import AccountSearch from 'components/AccountSearch';
import { Account } from 'models';
import { DialogActions } from '@mui/material';
import Api from 'api';
import { RequestMetaData } from 'components/DataGridInfiniteScroll/InterfaceDataGridPro';
import { ToastStore } from 'types/stores';
import Button from 'components/Button/Dialog/Button';
import theme from 'containers/App/theme';

interface ISearchAndSelectModalProps {
  close: () => void;
  userId: string;
  toastStore: ToastStore;
}

function SearchAndSelectModal({ close, userId, toastStore }: ISearchAndSelectModalProps) {
  const styles = useStyles();
  const [searchType, setSearchType] = useState('name');
  const [disableButton, setDisableButton] = useState(true);
  const [submitting, setSubmiting] = useState(false);
  const [accounts, setAccounts] = useState<Account[] | undefined>();

  const handleChange = (account: Account | null) => {
    if (!account) return;
    if (accounts && accounts.length) {
      setAccounts([...accounts, account]);
    } else {
      setAccounts([account]);
    }
  };

  useEffect(() => {
    handleDisableButton();
  }, [accounts]);

  const handleDisableButton = () => {
    if (accounts && accounts.length > 0) {
      return setDisableButton(false);
    }
    return setDisableButton(true);
  };

  const handleSearchType = (event: any) => {
    setSearchType(event.target.value as string);
  };

  const filterObject = (search: string): RequestMetaData => {
    if (searchType === 'name') {
      return { filters: { name: search, userId } };
    }
    return { filters: { code: search.toUpperCase(), userId } };
  };

  const saveOwnershipForAccounts = async () => {
    const accountIds = accounts!.map((account: Account) => account.id);
    try {
      setSubmiting(true);
      await api.core.addOwnershipForAccounts(userId, accountIds);
      setSubmiting(false);
      close();
      toastStore.success(`Ownership${accountIds.length > 1 ? 's' : ''} sucessfully assigned.`);
    } catch (e: any) {
      close();
      toastStore.error(e.message);
    }
  };

  const renderSelectedAccounts = () => {
    if (!accounts) return;
    return accounts.map(({store, code , tenant, id }: Account) => (
      <Chip
        key={id}
        color="primary"
        style={{ marginLeft: '1px', marginRight: '1px', marginTop: '2px' }}
        label={store ? store.name : tenant.name}
        onDelete={() => {
          setAccounts(accounts?.filter((_account: Account) => _account.id !== id));
        }}
      />
    ));
  };

  const renderAccountOption = ({store, code , tenant }: Account) => {
    return (
      <Box display="flex" flexDirection="column" width="100%">
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Box>{store ? store.name : tenant.name}</Box>
          <Box>{code}</Box>
        </Box>
        <Box component="span" className={styles.address}>
          {store ? store.address : tenant.address}
        </Box>
      </Box>
    );
  };

  return (
    <Box className={styles.searchAndSelectModalWrapper}>
      {submitting && (
        <Box className={styles.loader}>
          <CircularProgress />
        </Box>
      )}
      <DialogTitle disableTypography>
        <Typography style={{ fontSize: 22 }}> Assign ownership of selected account/s</Typography>
      </DialogTitle>
      <DialogContent>
        <Box className={styles.dialogContentWrapper}>
          <FormControl>
            <Select placeholder="Search by" value={searchType} onChange={handleSearchType}>
              <MenuItem value={'name'}>Search by: name</MenuItem>
              <MenuItem value={'code'}>Search by: code</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <AccountSearch
          renderOption={renderAccountOption}
          placeholder={`Account ${searchType}`}
          onChange={(account: Account | null) => handleChange(account)}
          clearSelection={{ clearDelay: 50 }}
          selectedAccounts={accounts}
          fetch={(search: any) => Api.core.getNonOwnedAccounts(filterObject(search))}
        />
        <Box style={{ marginTop: theme.spacing(1) }}>{accounts && renderSelectedAccounts()}</Box>
      </DialogContent>
      <DialogActions style={{ padding: theme.spacing(3) }}>
        <Button onClick={close} variant="outlined">
          Cancel
        </Button>
        <Button onClick={saveOwnershipForAccounts} disabled={disableButton} variant="contained">
          Assign
        </Button>
      </DialogActions>
    </Box>
  );
}

export default SearchAndSelectModal;
