import React from 'react';
import { Avatar, Box, List } from '@material-ui/core';
import { observer } from 'mobx-react';

import { inject, WithUserStore } from 'types/stores';
import ScopeItem from './ScopeItem';
import useStyles from './styles';
import PublicIcon from '@mui/icons-material/Public';
import { Account, Scope, Role } from 'models';
import theme from 'containers/App/theme';
// import GlobalOwnerSearch from './GlobalOwnerSearch/GlobalOwnerSearch';
import GlobalScopeSearch from './GlobalOwnerSearch/GlobalScopeSearch';
import { action, makeObservable, observable, reaction } from 'mobx';
import { useStores } from 'containers/App/App';
import { isValueInArray } from 'utils/helper';

interface ScopeSwitcherProps extends WithUserStore {
  close: () => void; // The callback to close the scope switcher
  classes: any;
}
/**
 * The scope switcher component. It lets the user change their current scope.
 * Someone's scope can be:
 * `user`: Viewing the dashboard as an SP
 * `location`: Viewing the dashboard as the manager of a certain location
 * `account`: Viewing the dashboard as the owner of a certain account
 */
@inject('userStore')
@observer
class ScopeSwitcher extends React.Component<ScopeSwitcherProps> {
  constructor(props: ScopeSwitcherProps) {
    super(props);
    makeObservable(this);

    reaction(
      () => this.props.userStore!.scope,
      (scope) => {
        this.scopeSelected = scope;
      },
    );
  }
  @observable private scopes = [...this.props.userStore!.accountsToDisplay]

  @observable private scopesToDisplay: Account[] = [...this.props.userStore!.accountsToDisplay];

  @action.bound public setScopesToDisplay(scopes: Account[]) {
    this.scopesToDisplay = scopes;
  }

  @observable private scopeSelected = this.props.userStore!.scope;

  getIcon(scope: string, account: Account | undefined) {
    let logo;
    let name;

    if (account) {
      logo = this.props.userStore!.getAccLogo(account);
      name = this.props.userStore!.getAccName(account);
    } else {
      logo = this.props.userStore?.user?.avatar;
      name = this.props.userStore?.user?.firstName;
    }

    return (
      <Avatar
        className={this.props.classes.scopeSwitcherAvatar}
        style={{
          backgroundColor: logo ? theme.palette.common.white : theme.palette.common.black,
        }}>
        {logo ? (
          <img src={logo} width={'100%'} height={'100%'} />
        ) : (
          <Box component={'span'} style={{ fontSize: '10px' }}>
            {name?.substring(0, 2)}
          </Box>
        )}
      </Avatar>
    );
  }

  render() {
    const { classes } = this.props;
    const userStore = this.props.userStore!;

    const showUserScope = userStore.user?.role === 'user';
    const showAccountScopes = isValueInArray(
      [Role.USER, Scope.MANAGER, Scope.EMPLOYEE],
      userStore.scope.kind,
    );

    const showScopeSwitcher = showAccountScopes;
    if (!showScopeSwitcher) {
      return null;
    }
    return (
      <>
        <Box px={3} pt={2} width={'100%'}>
          <GlobalScopeSearch
            userStore={userStore}
            scopes={this.scopes}
            setScope={this.setScopesToDisplay}
            selected={this.scopeSelected}
            onClose={this.props.close}
          />
        </Box>

        <List className={classes.list} disablePadding>
          {showUserScope && (
            <ScopeItem
              icon={this.getIcon('user', undefined)}
              scope={{ kind: 'user' }}
              active={userStore.scope.kind === 'user'}
              setScope={userStore.setScope}>
              {userStore.fullName}
            </ScopeItem>
          )}
          <Box className={this.props.classes.scopeItemWrapper}>
            {this.scopesToDisplay.map((scope: Account, index) => {
              return (
                <ScopeItem
                  key={index}
                  icon={this.getIcon(scope.scope, scope)}
                  scope={{ kind: scope.scope, accountId: scope.id }}
                  active={
                    userStore.scope.kind === scope.scope && userStore.scope.accountId === scope.id
                  }
                  setScope={userStore.setScope}>
                  {scope.store ? scope.store.name : scope.tenant.name}
                </ScopeItem>
              );
            })}
          </Box>
        </List>
      </>
    );
  }
}

const StyledScopeSwitcher = (props: Omit<ScopeSwitcherProps, 'classes'>) => {
  const { uiStore } = useStores();
  const { mobileView } = uiStore;
  return <ScopeSwitcher {...props} classes={useStyles({ mobileView })} />;
};

export default StyledScopeSwitcher;
