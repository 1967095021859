import React from 'react';
import { computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { WithStyles, withStyles } from '@material-ui/core/styles';

import { inject, WithUserStore, WithModalStore, WithToastStore } from 'types/stores';

import DashboardLayout from 'containers/DashboardLayout';
import logo from 'images/SHOPtree.svg';

import AdminHome from './AdminHome';
import NoRoleHome from './NoRoleHome';
import TenantHome from './TenantHome';

import { setTitle } from 'services';

import styles from './styles';
import { Box, CardMedia, Typography } from '@material-ui/core';
import clsx from 'clsx';


type DashboardHomeProps = WithStyles<typeof styles> &
  WithUserStore &
  WithModalStore &
  WithToastStore &
  RouteComponentProps;

/**
 * The dashboard home page, displayed when you log in.
 */
@inject('userStore')
@observer
class DashboardHome extends React.Component<DashboardHomeProps> {
  constructor(props: DashboardHomeProps) {
    super(props);
    // makeObservable(this);
  }
  componentDidMount() {
    setTitle(`Dashboard`, { noSuffix: true });
  }

  render() {
    const { classes } = this.props;
    if (!this.props.userStore || this.props.userStore!.loggingIn) {
      return (
        <Box className={classes.loadingWrapper}>
          <Box className={classes.loadingContent}>
            <CardMedia
              className={clsx(classes.shopLogo, classes.animatedItem)}
              image={logo}
              title={`SHOPtree logo`}
            />
            <Typography color="primary" variant="h5">
              Loading dashboard...
            </Typography>
          </Box>
        </Box>
      );
    }
    const role = this.props.userStore!.user?.role;
    if (role === 'admin') {
      return (
        <DashboardLayout>
          <AdminHome {...this.props} />
        </DashboardLayout>
      );
    } else if (role === 'user') {
      return (
        <DashboardLayout>
          <TenantHome {...this.props} />
        </DashboardLayout>
      );
    } else {
      return (
        <DashboardLayout>
          <NoRoleHome {...this.props} />
        </DashboardLayout>
      );
    }
  }
}

export default withStyles(styles)(DashboardHome);
