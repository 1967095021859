import moment from 'moment-timezone';
import { AxiosResponse } from 'axios';
import UAParser from 'ua-parser-js';

export function downloadCsvFile(data: any, outputFileName: string) {
  const downloadLink = document.createElement('a');
  const blob = new Blob(['\ufeff', data]);
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `${outputFileName}`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function formatGridTitleDate(date: string | undefined) {
  return date && moment(date).format('MMM, Do YYYY');
}

export enum DATE_TYPE {
  FULL = 'FULL',
  DATE = 'DATE',
  TIME = 'TIME',
}

const getFormattedDateTime = (date: moment.Moment, type: DATE_TYPE, formatString?: string) => {
  switch (type) {
    case DATE_TYPE.FULL:
      return date.format(formatString ? formatString : 'MMM DD, YYYY, hh:mm A');
    case DATE_TYPE.DATE:
      return date.format(formatString ? formatString : 'MMM DD, YYYY');
    case DATE_TYPE.TIME:
      return date.format(formatString ? formatString : 'hh:mm A');
  }
};

/**
 * This function returns formatted date string. Based on type it can return
 * either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatDateTimeInEST(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInEST = moment(date).tz('America/New_York');
  return getFormattedDateTime(dateInEST, type, formatString);
}

/**
 * This function returns formatted date string converted to local time in UTC format.
 * Based on type it can return either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatLocalDateTimeInUTC(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInUTC = moment(date).utc();
  return getFormattedDateTime(dateInUTC, type, formatString);
}

/**
 * This function returns formatted date string converted to local time.
 * Based on type it can return either date, time or datetime. Default formats: Date: 'MMM DD', Time: 'hh:mm A', DateTime: 'MMM DD, YYYY, hh:mm A'
 * @param date date string.
 * @param formatString custom override for datetime string.
 * @param type enum of type DATE_TYPE. Defaults to DATE.
 */
export function formatLocalDateTime(
  date?: string,
  type: DATE_TYPE = DATE_TYPE.DATE,
  formatString?: string,
) {
  if (!date) return;
  let dateInLocale = moment(date);
  return getFormattedDateTime(dateInLocale, type, formatString);
}

//** Extracting filename from response headers for csv reports */
export function getFileNameFromResponseHeaders(res: AxiosResponse): string {
  const headerLine = res.headers['content-disposition'] || res.headers['Content-Disposition'];
  const startFileNameIndex = headerLine.indexOf('"') + 1;
  const endFileNameIndex = headerLine.lastIndexOf('"');
  const filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
  return filename;
}

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string.
 */
export function decamelize(str: string, separator: string) {
  separator = typeof separator === 'undefined' ? '_' : separator;

  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
}

/**
 * @param precision how many decimal places come out
 * @param divider number of people for distribution
 * @param numerator amount for distribution
 * @returns an array of equally distributed values between dividers
 */
export const distribute = (precision: number, divider: number, numerator: number): number[] => {
  const arr = [];
  while (divider > 0) {
    const amount =
      Math.round((numerator / divider) * Math.pow(10, precision)) / Math.pow(10, precision);
    arr.push(amount);
    numerator -= amount;
    divider--;
  }
  return arr.sort((a, b) => b - a);
};
/**
 *
 * @param str
 * @returns a humanized capitalized string
 */
export function humanize(str: string) {
  const frags = str.split('_');
  for (let i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}

/**
 *
 * @param str
 * @returns capitalized str string
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * This function geneates a key based on given inputs
 */
export function createKey(arg1: string | number, arg2: string | number) {
  return `${arg1}-${arg2}`;
}

/**
 * This function gets index value of an enum for a given value
 * @param value enum value
 * @param obj enum object
 * @returns index of enum for given value
 */
export function getKeyByValue(value: string, obj: any) {
  return Object.values(obj).indexOf(value);
}

/**
 *
 * @param arr array of string values
 * @param value string value
 * @returns true if array includes given value, otherwise returns false
 */
export function isValueInArray(arr: string[], value: string): boolean {
  return arr.includes(value);
}

export function isOsAndroid() {
  const uaString = navigator.userAgent;
  let parser = new UAParser(uaString);
  return parser.getOS().name?.toLocaleLowerCase().includes('android');
}

/**
 *
 * @param url URL to change to deep link
 * @param schema Schema to use eg. Shop://
 * @returns Deep link URL
 */
export function generateDeepLink(url: string, schema: string) {
  return url.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+/, schema);
}
