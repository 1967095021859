import React from 'react';
import { observable, action, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Dialog, Typography } from '@material-ui/core';
import { Circle, Plus } from 'mdi-material-ui';
import Api, { RequestMetaData } from 'api';
import DP from 'components/DashPanel';
import AddBusiness from 'components/AddBusiness';
import { Store } from 'models';
import { paths } from 'routes';
import debounce from 'lodash/debounce';

import styles, { useStyles } from './styles';
import { EmptyPanelMessage } from 'components/EmptyPanelMessage/EmptyPanelMessage';

/** Represents a single device in the list */
const StoreListItem = observer(({ children }: { children: Store }) => {
  const store = children;
  const secondaryText = `${store.address.city} ${store.address.state} | ${store.code}`;
  const menu = [
    {
      label: 'Coming soon',
      onClick: () => {},
    },
  ];

  const classes = useStyles();
  return (
    <DP.ListItem
      key={store.id}
      icon={
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ fontSize: '21px' }}>
          <Circle fontSize="inherit" color={store.isActive ? 'primary' : 'error'} />
        </Box>
      }
      primary={
        <Link component={RouterLink} to={paths.storeDetails(store.id).root()} color="textPrimary">
          {store.name}
        </Link>
      }
      secondary={<Typography className={classes.textSecondary}>{secondaryText}</Typography>}
      menu={menu}
    />
  );
});

interface StoresPanelProps extends WithStyles<typeof styles> {
  showAddButton?: boolean;
  tenantId: string;
}

/**
 * Displays a scrollable list of stores for quick viewing. Includes
 * a filter.
 */
@observer
class StoresPanel extends React.Component<StoresPanelProps> {
  constructor(props: StoresPanelProps) {
    super(props);
    makeObservable(this);
  }

  private panelName = 'Stores';

  @observable public stores?: Store[];

  /** The search text */
  @observable private searchText = '';

  /** Whether the modal for adding a  is open */
  @observable public addModalOpen = false;

  @action.bound public openAddModal() {
    this.addModalOpen = true;
  }

  @action.bound public closeAddModal() {
    this.addModalOpen = false;
  }

  @action.bound public getStores = async (name?: string) => {
    const query = {
      filters: {
        ...(name && { name }),
        tenantId: this.props.tenantId,
      },
    } as RequestMetaData;

    const resp = await Api.core.getStores(query);
    this.stores = resp.data.data;
  };

  /** A total count of the stores */
  @computed private get count() {
    return this.stores && this.stores.length;
  }

  @computed get numberOfstores() {
    if (this.stores) {
      return this.stores.length;
    } else {
      return 0;
    }
  }

  /** Updates the search text */
  @action.bound private handleSearchOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.searchText = e.target.value;
    this.updateFilterDebounced(e.target.value);
    if (e.target.value === '') {
      this.updateFilterDebounced &&
        this.updateFilterDebounced.flush &&
        this.updateFilterDebounced.flush();
    }
  }

  @action.bound private async updateFilter(s?: string) {
    await this.getStores(s)
  }

  private updateFilterDebounced = debounce(this.updateFilter, 500);

  componentDidMount(): void {
      this.updateFilter()
  }


  render() {
    const { showAddButton, tenantId, classes } = this.props;
    if (!this.stores) {
      return (
        <DP>
          <DP.Header>
            <DP.Title panel>Stores</DP.Title>
          </DP.Header>
          <DP.Body>
            <DP.Loading items={4} />
          </DP.Body>
        </DP>
      );
    }
    return (
      <DP>
        <DP.Header>
          <Dialog open={this.addModalOpen} onClose={this.closeAddModal} fullWidth>
            <AddBusiness
              onSubmit={(v)=> {console.log(v)}}
              onClose={this.closeAddModal}
              title='Add store'
            />
          </Dialog>
          <DP.Title count={this.count} panel>
            {this.panelName}
          </DP.Title>
          {showAddButton && (
            <DP.Actions>
              <DP.IconButton
                icon={Plus}
                tooltip="Add store"
                primary
                onClick={this.openAddModal}
              />
            </DP.Actions>
          )}
        </DP.Header>
        {this.stores && this.stores.length > 0 ? (
          <Box>
            <DP.SearchInput value={this.searchText} onChange={this.handleSearchOnChange} />

            <DP.List height={this.numberOfstores < 5 ? 'short' : 'normal'}>
              {this.stores.map((store) => (
                <StoreListItem key={store.id}>{store}</StoreListItem>
              ))}
            </DP.List>
          </Box>
        ) : (
          <DP.Body>
            <Box display={'flex'} alignContent={'center'}>
              <EmptyPanelMessage panelTitle={this.panelName} />
            </Box>
          </DP.Body>
        )}
      </DP>
    );
  }
}

export default withStyles(styles)(StoresPanel);
