// This file defines functions that return the path strings.
// Always reference this so that we have a unified source for
// the paths, in case they change.
/** The root path */
export const root = () => '/dashboard';
/** The sign-in screen */
export const signIn = () => '/sign-in';
/** The users */
export const users = () => {
  const base = `/users`;
  return {
    root: () => base,
    merge: () => `${base}/merge`,
  };
};

export const invitations = () => '/invitations';

/** The user details screen */
export const userDetails = (userId: string | number) => {
  const base = `/users/${userId}`;
  return {
    root: () => base,
    info: () => base,
    workspace: () => `${base}/workspace`,
    wallet: () => `${base}/wallet`,
    tips: () => `${base}/tips`,
  };
};





/** Download tips report */
export const downloadTipsReport = () => `/tips/report/download`;
/** Tips for talent scope */
export const myTips = () => `/mytips`;
/** All tips scope */
export const tips = () => `/tips`;
export const tipsDynamics = (accountId: string) => `/tips/${accountId}`;

/** List of customer payments */
export const customerPayments = (id: number | string) => `/customers/${id}/payments`;
/** The payments screen */
export const payments = () => `/payments`;
export const paymentAccount = (accountId: string) => `/payments/${accountId}`;
// export const paymentsDynamics = (id?: number | string | undefined) => `/payments/${id}`;
/** Payment tips */
export const paymentTips = (paymentReference: string) => `/payments/${paymentReference}/tips`;
/** Analytics */
export const analytics = () => {
  const base = `/analytics`;
  return {
    tipsReport: () => `${base}/tips`,
    revenueOverview: () => `${base}/revenue`,
    stateRevenue: (stateLabel: string) => `${base}/revenue/state/${stateLabel}`,
    locationRevenue: (locationId: string | number) => `${base}/revenue/location/${locationId}`,
    activity: () => `${base}/activity`,
    accounts: () => `${base}/activity/accounts`,
    devices: () => `${base}/activity/devices`,
    tips: () => `${base}/activity/accounts/tips`,
    performance: () => ({
      root: () => `${base}/performance`,
      locations: () => `${base}/performance/locations`,
      talent: () => `${base}/performance/talent`,
    }),
    conversion: () => ({
      root: () => `${base}/conversion`,
      byAffiliates: () => `${base}/conversion/by-affiliates`,
      byCampaign: () => `${base}/conversion/by-campaign`,
      byDeal: () => `${base}/conversion/by-deal`,
      byEmployee: () => `${base}/conversion/by-employee`,
      bySource: () => `${base}/conversion/by-source`,
    }),
  };
};
/** Account signup for admins */
export const adminAccountSignup = () => {
  const base = `/accounts/add`;
  return {
    root: () => base,
    owner: () => `${base}/owner`,
    account: () => `${base}/account`,
    product: (accountId: number | string) => `${base}/${accountId}/product`,
    locations: (accountId: number | string) => `${base}/${accountId}/locations`,
    coupons: (accountId: number | string) => `${base}/${accountId}/coupons`,
    review: (accountId: number | string) => `${base}/${accountId}/review`,
  };
};

/** Account signup for affiliates */
export const affiliateAccountSignup = () => {
  const base = `/affiliate-program/accounts/add`;
  return {
    root: () => base,
    owner: () => `${base}/owner`,
    account: () => `${base}/account`,
    locations: (accountId: number | string) => `${base}/${accountId}/locations`,
    coupons: (accountId: number | string) => `${base}/${accountId}/coupons`,
    review: (accountId: number | string) => `${base}/${accountId}/review`,
  };
};

/**
  Endpoints that would have to be opened up for affilliates:
  
  GET /core/v1/users/by-email/:email (for getting existing owners when adding accounts)
  PATCH /core/v1/users/:userId (for setting firstName, lastName and phone to created users)
  POST /core/v1/accounts/:accountId/owners (for setting the owner of the newly created account)
  GET /core/v1/accounts/:accountId/owners (for getting the primary owner for the "owner works here" box)
  GET /core/v1/accounts/:accountId (the affiliate needs access to this endpoint so we can display account data)
  POST /core/v1/locations (the affiliate does not have permissions to create locations for an account)
  POST /core/v1/locations/:locationId/user (the affiliate does not have permission to add users to just any location)

  GET /billing/v1/cart/:accountId (the affiliate can't fetch the cart of some account)
  PATCH /billing/v1/cart/:accountId (the affiliate has to be able to somehow set themselves as the affiliate of an account's cart)
  POST /billing/v1/cart/:accountId/item (the affiliate does not have permissions to add items to an account's cart)
  POST /billing/v1/cart/:accountId/promotion (the affiliate does not have permission to add promotions to an account's cart)
  POST /billing/v1/cart/:accountId/send-purchase-order (does not have permission to send purchase orders for account)
 */
export const reports = () => '/reports';

export const plaidRedirectUri = () => '/plaid/redirect-uri';
export const rosyCallback = () => '/callbacks/rosy/:accountId';
/** Locations  */
export const locations = () => '/locations';
/** Forgot password page */
export const forgotPassword = () => '/forgot-password';
/** Reset password page */
export const resetPassword = (path?:string) => path ? `/reset-password/${path}`:'/reset-password';

/** Account details page */
export const changeEmail = () => `/confirm-email-change/:token`;
/** The accounts list */

export const QRCodeForPrint = () => `/qr-code`;
/** Location details page */
export const locationDetails = (id: string | number) => `/locations/${id}`;
/** Location details page */
export const locationMap = () => `/locations/map`;
/** Salon Centric page */
export const salonCentricInquiry = () => `/salon-centric-inquiry`;
/** Summit page */
export const summitInquiry = () => `/summit-inquiry`;
/** Art of business page */
export const aobInquiry = () => `/art-of-business`;
/** MyAccount page */
export const myAccount = () => {
  const base = `/myaccount`;
  return {
    root: () => base,
    profile: () => base,
    workspace: () => `${base}/workspace`,
    deposits: () => `${base}/deposits`,
  };
};
/** Sales  */
export const sales = () => {
  const base = `/sales`;
  return {
    root: () => base,
    pendingCarts: () => `${base}/carts/pending`,
    completedCarts: () => `${base}/carts/completed`,
  };
};
/** Affiliate Program  */
export const affiliateProgram = () => {
  const base = `/affiliate-program`;
  return {
    root: () => base,
    conversions: () => `${base}/conversions`,
    payouts: () => `${base}/payouts`,
    reports: () => `${base}/reports`,
  };
};

/** Marketing Menu  */
/** Affiliates  */
export const affiliates = () => '/affiliates';
/** Leads  */
export const leads = () => '/leads';
/** Conversions  */
export const conversions = () => '/conversions';

/** Campaigns  */
export const campaignsByCode = (code: string) => `/campaign/${code}`;

export const signUp = () => {
  const base = '/sign-up';
  return {
    root: () => base,
    personalInfo: () => `${base}/personal-info`,
    personalInfo2: () => `${base}/additional-info`,
    profession: () => `${base}/profession`,
    location: () => `${base}/location`,
    bank: ({ instant }: { instant?: boolean } = {}) =>
      instant ? `${base}/bank?instant=true` : `${base}/bank`,
    confirmEmailScreen: () => `${base}/confirm-email`,
    profilePicture: () => `${base}/profile-picture`,
    netspend: () => {
      const netspendBase = `${base}/netspend`;
      return {
        base: () => netspendBase,
        enroll: () => `${netspendBase}/enroll`,
        link: () => `${netspendBase}/link`,
        confirmAddress: () => `${netspendBase}/confirm-address`,
        ssn: () => `${netspendBase}/ssn`,
        final: () => `${netspendBase}/final`,
      };
    },
    success: () => `${base}/success`,
  };
};

/** Account self-sign up, also known as the register process */
export const register = () => {
  const base = '/register';
  return {
    root: () => base,
    confirmEmailScreen: () => `${base}/confirm-email`,
    personalInfo: () => `${base}/personal-info`,
    companyInfo: () => `${base}/company-info`,
    locations: (accountId: number | string) => `${base}/${accountId}/locations`,
    promotions: (accountId: number | string) => `${base}/${accountId}/promotions`,
  };
};

/** Simplified manager sign up, initiated via invitation link */
export const managerSignUp = () => `/manager/sign-up`;

/** Form for linking user's bank account to Dwolla */
export const linkBank = (encodedUserData: string) => `/link-bank/${encodedUserData}`;

export const affiliateSignUp = () => `/affiliate/sign-up`;
export const affiliateSignUpOrg = () => `/sign-up/organization`;

export const externalAppLogin = () => `/sign-up/external-app-login`;

export const checkout = (cartId: string) => `/checkout/${cartId}`;
export const checkoutPreview = () => `/checkout-preview`;

export const infoDisplay = () => `/info`;

export const customers = () => `/customers`;

export const deposits = () => {
  const base = '/deposits';
  return {
    userPayouts: () => `${base}/user_payouts`,
    partnerPayouts: () => `${base}/partner_payouts`,
    cards: () => `${base}/cards`,
    initiationDetails: (id: string | number) => `${base}/initiations/${id}`,
    chunkDetails: (id: string | number, chunkId: string | number) =>
      `${base}/initiations/${id}/chunks/${chunkId}`,
  };
};

export const revshare = () => {
  const base = `/revshare`;
  return {
    root: () => base,
    feeSchedule: () => `${base}/fee-schedule`,
    partners: () => `${base}/partners`,
  };
};

export const partnerDetails = (id: string | number) => {
  const base = `${revshare().partners()}/${id}`;

  return {
    root: () => base,
    general: () => `${base}/general`,
    wallet: () => `${base}/wallet`,
  };
};

export const refunds = () => {
  const base = `/refunds`;
  return {
    root: () => base,
    requests: () => `/refund-requests`,
  };
};

export const alerts = () => `/alerts`;


export const payoutsTransactions = (id: string | number) => `/payouts/${id}`;

export const dwollaTransaction = (id: string) =>
  `https://dashboard.dwolla.com/transactions?transfer=${id}`;

export const devices = () => {
  const base = `/devices`;
  return {
    root: () => base,
  };
};

export const devicesShipments = (hardwareId?: string | number) => {
  const base = `/devices-shipments`;
  return {
    root: () => `${base}/${hardwareId}`,
  };
};

export const integrations = () => `/integrations`;

export const webKiosk = () => {
  return {
    fwd: (code: string) => `/kiosk/${code}`,
    touchless: (deviceId: string, intent: string) => `/kiosk/qr/${deviceId}/${intent}`,
    payLater: (deviceId: string, scheduledPaymentIntentCode: string) =>
      `/kiosk/pay-later/${deviceId}/${scheduledPaymentIntentCode}`,
  };
};

export const receipt = (reference: string) => `/payments/receipts/${reference}`;

export const savings = () => `/savings`;

/** For now, this will just point to dashboard, as that's where you can accept your invitations */
export const acceptInvitations = () => `/accept-invitations`;

export const liability = () => `/liability`;

export const pendingTips = () => {
  const pendingTipsBase = `/pending-tips`;
  return {
    base: () => pendingTipsBase,
    undistributed: () => `${pendingTipsBase}/undistributed`,
    recipients: () => `${pendingTipsBase}/recipients`,
    review: () => `${pendingTipsBase}/review`,
  };
};

export const draw = () => {
  const drawBase = '/draw';
  return {
    balances: () => `${drawBase}/balances`,
    draws: () => `${drawBase}/draws`,
    transactions: () => `${drawBase}/transactions`,
  };
};

export const reconciliation = () => {
  const base = `/reconciliation`;
  return {
    root: () => `${base}`,
    byDate: () => `${base}/by-date`,
    byEmployee: () => `${base}/by-employee`,
    byTransaction: () => `${base}/by-transaction`,
  };
};

export const referrals = () => {
  const base = `/referrals`;
  return {
    root: () => `${base}`,
  };
};


export const plans = () => `/plans`;


export const tenants = () => `/tenants`;

export const tenantDetails = (tenantId: string) => {
  const base = `/tenants/${tenantId}`;
  return {
    root: () => base,
    info: () => base,
    billing: () => `${base}/billing`,
  };
};

export const onBoardTenant = () => {
  const base = `/onboard`;
  return {
    root: () => base,
    owner: () => `${base}/owner`,
    company: () => `${base}/company`,
    plan: () => `${base}/plan`,
    review: () => `${base}/review`,
  };
};

export const stores = () => `/stores`;

export const storeDetails = (storeId: string) => {
  const base = `/stores/${storeId}`;
  return {
    root: () => base,
    info: () => base,
    billing: () => `${base}/billing`,
  };
};


export const accounts = () => '/accounts';
/** Account details routes */
export const accountDetails = (accountId: number | string) => {
  const base = `/accounts/${accountId}`;
  return {
    root: () => base,
    info: () => base,
    billing: () => `${base}/billing`,
    devices: () => `${base}/devices`,
    transactions: () => `${base}/billing/transactions`,
    updateCard: () => `${base}/update-card`,
  };
};

export const brands = () => `/brands`;
export const categories = () => `/categories`;


export const samples = () => `/samples`;
export const orders = () => `/orders`;