import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Link as RouterLink, Redirect, Route, RouteComponentProps } from 'react-router-dom';
import { action, computed, flow, observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Api from 'api';
import { inject, WithModalStore, WithUserStore } from 'types/stores';
import { paths } from 'routes';
import { Store } from 'models';
import DashboardLayout from 'containers/DashboardLayout';
import EditableTitle from 'components/EditableTitle';
import TabBar from 'components/TabBar';
import styles from './styles';
import Info from './Info';
import { setTitle } from 'services';

const PAGE_TITLE = 'Store';
interface StoreDetailsMatchParams {
  storeId: string;
}

type StoreDetailsProps = WithStyles<typeof styles> & // Adds the classes prop
  RouteComponentProps<StoreDetailsMatchParams> & // Adds the router props (history, match, location)
  WithModalStore & // Adds modalStore prop
  WithUserStore; // Adds the userStore prop

@inject('modalStore', 'userStore')
@observer
class StoreDetails extends React.Component<StoreDetailsProps> {
  public constructor(props: StoreDetailsProps) {
    super(props);
    makeObservable(this);
    this.matchParams = this.props.match.params;
  }

  @observable public matchParams: StoreDetailsMatchParams;

  @observable public store?: Store = undefined;

  @computed public get storeId(): string {
    return this.matchParams.storeId;
  }
  

  @action.bound public getStore = flow(function* (this: StoreDetails) {
    const resp = yield Api.core.getStore(this.storeId);
    this.store = resp.data
  });

  @action.bound public changeStoreName = flow(function* (this: StoreDetails, newTitle: string) {
    const resp = yield Api.core.updateStore(this.storeId, { name: newTitle });
    this.store = resp.data
  });

  componentDidMount() {
    this.getStore();
    setTitle(PAGE_TITLE, { noSuffix: false });
  }

  render() {
    if (!(this.props.userStore?.isAdmin || this.props.userStore?.isOwner || this.props.userStore?.isManager)) {
      return <Redirect to={paths.root()} />;
    }

    const storeDetailsPath = paths.storeDetails(this.storeId);
    const { pathname } = this.props.location;
    return (
      <DashboardLayout>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent={'space-between'}>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {this.store && (
              <EditableTitle
                entity={this.store}
                editableLogo={true}
                onRefresh={this.getStore}
                onUpload={Api.core.uploadStoreLogo}
                onChange={this.changeStoreName}
              />
            )}
          </Box>
        </Box>
        <TabBar mb={3}>
          <TabBar.Tab
            component={RouterLink}
            to={storeDetailsPath.info()}
            selected={pathname === storeDetailsPath.info()}>
            General
          </TabBar.Tab>
          <TabBar.Tab
            component={RouterLink}
            to={storeDetailsPath.billing()}
            selected={
              pathname === storeDetailsPath.billing()
            }>
            Billing
          </TabBar.Tab>
        </TabBar>
        <Route
          path={storeDetailsPath.info()}
          exact
          render={(props: any) => (
            <Info {...props} store={this.store} storeId={this.storeId} />
          )}
        />
        <Route
          path={storeDetailsPath.billing()}
          exact
          render={(props: any) => (
            <>Billing</>
          )}
        />
      </DashboardLayout>
    );
  }
}
export default withStyles(styles)(StoreDetails);
