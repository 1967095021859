/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { observable, action, flow, computed, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import Api, * as api from 'api';
import { inject, WithUserStore, WithToastStore } from 'types/stores';
import type { Store } from 'models';
import UsersPanel from 'components/UsersPanel';
import CodePanel from 'components/CodePanel';
import AddressPanel from 'components/AddressPanel';
import styles from './styles';
import Masonry from '@mui/lab/Masonry';
import { RequestMetaData } from 'api';

/** Here we define what kind of props this component takes */
interface InfoProps
  extends WithStyles<typeof styles>,
    WithUserStore,
    RouteComponentProps,
    WithToastStore {
  store: Store;
  storeId: string;
}
/**
 * Container for account address, primary owner, locations and users panels.
 */
@inject('userStore', 'toastStore')
@observer
class Info extends React.Component<InfoProps> {
  constructor(props: InfoProps) {
    super(props);
    makeObservable(this);
  }

  @computed public get store() {
    return this.props.store;
  }

  @computed public get isAdmin(): boolean {
    return Boolean(this.props.userStore?.isAdmin);
  }

  @action.bound public getUsersBystore = async (rmd?: RequestMetaData) => {
    const options = {
      ...rmd,
      filters: {
        ...(rmd?.filters && rmd?.filters),
        storeId: this.props.storeId,
      },
    } as RequestMetaData;

    return await Api.core.getUsersByStore(options);
  };

  render() {
    return (
      <Grid container direction={'row'} spacing={3}>
        <Grid item lg={3}>
          {this.store && <CodePanel title="Store code" code={this.store.code} />}
        </Grid>

        <Masonry columns={{ xs: 1, md: 2 }} spacing={3} style={{ margin: 0 }}>
          <Box display={'flex'} style={{ gap: '24px' }}>
            <Box style={{ width: '50%', minWidth: '194px' }}>
              {this.store && (
                <AddressPanel
                  addressEditable={false}
                  fullHeight
                  title="Address"
                  onUpdateAddress={undefined}>
                  {this.store.address}
                </AddressPanel>
              )}
            </Box>
          </Box>

          <Box style={{ minWidth: '268px' }}>
            <UsersPanel
              fetch={this.getUsersBystore}
              renderScope={['employee', 'manager']}
              displayInviteManagersButton
            />
          </Box>
        </Masonry>
      </Grid>
    );
  }
}

export default withStyles(styles)(Info);
